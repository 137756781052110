.privacy-v1 {
	margin-top: var(--page-margin-top);
	/*padding-top: var(--page-padding-top);*/

	ol {
		li {
			margin-top: 20px;
		}
	}

	ul {
		padding: 0 0 0 60px;
		list-style: disc;
	}
}