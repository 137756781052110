.contact-page-layout-v1 {
	margin-top: var(--page-margin-top);

	.contact-logo {
		border-radius: var(--border-radius);
		border: 1px solid var(--color-grey-dark);
		height: 130px;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 20px 10px;

		img {
			max-height: 80px;
			max-width: 160px;

			@media(min-width:768px) and (max-width:1200px) {
				max-width: 120px;
			}
		}
	}
}