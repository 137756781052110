/* barlow-semi-condensed-regular - latin */
@font-face {
	font-family: 'Barlow Semi Condensed';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('https://r.v-office.com/fonts/barlow-semi-condensed-v14-latin-regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('https://r.v-office.com/fonts/barlow-semi-condensed-v14-latin-regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}


@font-face {
	font-family: 'Playfair Display';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('https://r.v-office.com/fonts/playfair-display-v29-latin-regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('https://r.v-office.com/fonts/playfair-display-v29-latin-regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: url('RESOURCE/fonts/OpenSans-SemiBold.eot');
	src: url('RESOURCE/fonts/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/OpenSans-SemiBold.woff2') format('woff2'),
		url('RESOURCE/fonts/OpenSans-SemiBold.woff') format('woff'),
		url('RESOURCE/fonts/OpenSans-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: url('RESOURCE/fonts/OpenSans-Bold.eot');
	src: url('RESOURCE/fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/OpenSans-Bold.woff2') format('woff2'),
		url('RESOURCE/fonts/OpenSans-Bold.woff') format('woff'),
		url('RESOURCE/fonts/OpenSans-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: url('RESOURCE/fonts/OpenSans-Light.eot');
	src: url('RESOURCE/fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/OpenSans-Light.woff2') format('woff2'),
		url('RESOURCE/fonts/OpenSans-Light.woff') format('woff'),
		url('RESOURCE/fonts/OpenSans-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: url('RESOURCE/fonts/OpenSans-Medium.eot');
	src: url('RESOURCE/fonts/OpenSans-Medium.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/OpenSans-Medium.woff2') format('woff2'),
		url('RESOURCE/fonts/OpenSans-Medium.woff') format('woff'),
		url('RESOURCE/fonts/OpenSans-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: url('RESOURCE/fonts/OpenSans-Regular.eot');
	src: url('RESOURCE/fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/OpenSans-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/OpenSans-Regular.woff') format('woff'),
		url('RESOURCE/fonts/OpenSans-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Libre Baskerville';
	src: url('RESOURCE/fonts/LibreBaskerville-Regular.eot');
	src: url('RESOURCE/fonts/LibreBaskerville-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/LibreBaskerville-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/LibreBaskerville-Regular.woff') format('woff'),
		url('RESOURCE/fonts/LibreBaskerville-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Libre Baskerville';
	src: url('RESOURCE/fonts/LibreBaskerville-Bold.eot');
	src: url('RESOURCE/fonts/LibreBaskerville-Bold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/LibreBaskerville-Bold.woff2') format('woff2'),
		url('RESOURCE/fonts/LibreBaskerville-Bold.woff') format('woff'),
		url('RESOURCE/fonts/LibreBaskerville-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Libre Baskerville';
	src: url('RESOURCE/fonts/LibreBaskerville-Italic.eot');
	src: url('RESOURCE/fonts/LibreBaskerville-Italic.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/LibreBaskerville-Italic.woff2') format('woff2'),
		url('RESOURCE/fonts/LibreBaskerville-Italic.woff') format('woff'),
		url('RESOURCE/fonts/LibreBaskerville-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}